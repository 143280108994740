import React from 'react';
import { Card, Typography, Box } from '@mui/material';

const CircularCard = ({ imageSrc, text }) => {
    return (
        <Card
            sx={{
                width: {md:300,xs:200},
                height: {md:300,xs:200},
                borderRadius: '50%',
                border: '10px solid white',
                overflow: 'hidden',
                boxShadow: 3, // Adds a shadow effect
                position: 'relative',
                backgroundImage: `url(${imageSrc})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <Box
                sx={{
                    position: 'absolute',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                    height: '100%',
                }}
            >
                <Typography
                    variant="h6"
                    sx={{
                        color: 'white',
                        fontWeight: 'bold',
                    }}
                >
                    {text}
                </Typography>
            </Box>
        </Card>
    );
};

export default CircularCard;
