import * as React from "react";
import Footer from "./components/Footer";
import Base from "./components/Base";
import Home from "./pages/Home"

function App() {
    return (
        <div>
            <Base />
            <Home />
            <Footer />
        </div>
    );
}
export default App;