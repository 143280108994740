import React from 'react';
import { Card, Typography, Box } from '@mui/material';

const LargeCardRaw = ({ imageSrc, title }) => {
    return (
        <Card
            sx={{
                maxWidth: {md:600,xs:'100%'},
                width: {md:600,xs:"100%"},
                height: 600,
                borderRadius: 5,
                overflow: 'hidden',
                boxShadow: 3, // Adds a shadow effect
                position: 'relative',
                backgroundImage: `url(${imageSrc})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <Box
                sx={{
                    position: 'absolute',
                    display: 'flex',
                    justifyContent: 'flex-start',
                    px:4,
                    py: 10,
                    gap: 2,
                    alignItems: 'flex-end',
                    width: '100%',
                    height: '100%',
                }}
            >
                <Typography
                    sx={{
                        fontSize: 35,
                        color: 'white',
                        fontWeight: 'bold',
                        textWrap: 'wrap'
                    }}
                >
                    {title}
                </Typography>

            </Box>
        </Card>
    );
};

export default LargeCardRaw;
