import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import {ReactComponent as Logo} from "../assets/logo.svg";

const drawerWidth = 240;
const navItems = ['Home', 'Construction', 'About', 'Sell Property', 'Contact Us'];

function Header(props) {
    const {window,value} = props;
    const [mobileOpen, setMobileOpen] = React.useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen((prevState) => !prevState);
    };

    const drawer = (
        <Box onClick={handleDrawerToggle} sx={{textAlign: 'center'}}>
            <Logo/>
            <Divider/>
            <List>
                {navItems.map((item,index) => (
                    <ListItem key={item} disablePadding>
                        <ListItemButton sx={{textAlign: 'center',backgroundColor:index===navItems.length-1?"#00B5EF":""}}>
                            <ListItemText primary={item}/>
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
        </Box>
    );

    const container = window !== undefined ? () => window().document.body : undefined;

    return (
        <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-between',backgroundColor:value}}>
            <CssBaseline/>
            <AppBar
                component="nav"
                sx={{
                    backgroundColor: "inherit",
                    boxShadow: 'none',
                    p: 4
                }}>
                <Toolbar sx={{display: 'flex', justifyContent: 'space-between'}}>
                    {/* First Box - Logo and Title Section */}
                    <Box
                        sx={{
                            display: 'flex',
                            // alignItem:'end',
                            justifyContent:'center',
                            // alignItems: 'center',
                            // ml: 'auto', // Push this box to the end
                            width: {md:'50%'}, // Take half of the width
                         
                        }}
                    >
                        <Logo/>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                mx: 2,
                            }}
                        >
                            <Typography
                                variant="h6"
                                component="div"
                                sx={{color: '#00B5EF', fontWeight: 'bold'}}
                            >
                                Shree Shivay
                            </Typography>
                            <Typography
                                variant="h6"
                                component="div"
                                sx={{color: '#00B5EF', fontWeight: 'bold'}}
                            >
                                Infrastructure
                            </Typography>
                        </Box>
                    </Box>

                    {/* Second Box - Navigation Items */}
                    <Box
                        sx={{
                            display: {xs: 'none', sm: 'flex'},
                            alignItems: 'center',
                            justifyContent: 'flex-start',
                            width: '50%', // Take the other half of the width
                        }}
                    >
                        {navItems.map((item, index) => (
                            <Button
                                key={item}
                                sx={{
                                    mx: 1,
                                    color: index === navItems.length - 1 ? "white" : "",
                                    backgroundColor: index === navItems.length - 1 ? "#00B5EF" : ""
                                }}
                            >
                                {item}
                            </Button>
                        ))}
                    </Box>

                    {/* Mobile Menu Icon */}
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        sx={{display: {sm: 'none'}}}
                    >
                        <MenuIcon sx={{color: '#00B5EF'}}/>
                    </IconButton>
                </Toolbar>

            </AppBar>
            <nav>
                <Drawer
                    container={container}
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    sx={{
                        display: {xs: 'block', sm: 'none'},
                        '& .MuiDrawer-paper': {boxSizing: 'border-box', width: drawerWidth},
                    }}
                >
                    {drawer}
                </Drawer>
            </nav>
        </Box>
    );
}

export default Header;
