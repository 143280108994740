import React from 'react';
import { Card, Typography, Box } from '@mui/material';
import Button from "@mui/material/Button";

const RectangleCard = ({ imageSrc, title,tag }) => {
    return (
        <Card
            sx={{
                maxWidth: 300,
                flexShrink:0,
                width: {md:250,xs:200},
                height: {md:500,xs:300},
                borderRadius: 2,
                border: '2px solid white',
                overflow: 'hidden',
                boxShadow: 3, // Adds a shadow effect
                position: 'relative',
                backgroundImage: `url(${imageSrc})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <Box
                sx={{
                    position: 'absolute',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-end',
                    p:4,
                    gap: 2,
                    alignItems: 'flex-start',
                    width: '100%',
                    height: '100%',
                }}
            >
                <Typography
                    variant="subtitle1"
                    sx={{
                        color: 'white',
                    }}
                >
                    {tag}
                </Typography>
                <Typography
                    variant={{mx:"h5",sm:"body1"}}
                    sx={{
                        color: 'white',
                        fontWeight: 'bold',
                        textWrap: 'wrap'
                    }}
                >
                    {title}
                </Typography>
                <Button
                    variant="contained"
                    sx={{
                        textTransform:'none',
                        backgroundColor: "transparent",
                        border: '1px solid white',
                        width: 'fit-content',  // Automatically sizes to fit content
                        px:2,  // Adds horizontal padding
                        py:1,
                        fontSize: 17
                    }}
                >
                    More Details
                </Button>
            </Box>
        </Card>
    );
};

export default RectangleCard;
