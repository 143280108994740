import React from 'react';
import { Card, CardContent, Avatar, Typography, Box } from '@mui/material';
import StarIcon from '@mui/icons-material/Star';

const CustomerReviewCard = ({avatar}) => {
  return (
    <Card 
      sx={{
        maxWidth: 600, 
        padding: 4, 
        borderRadius: 2, 
        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)", 
        border: "1px solid #e0e0e0"
      }}
    >
      <Box display="flex" alignItems="flex-start" flexDirection="row" gap={4}>
        <Avatar
          src={avatar} // Replace with actual image URL
          alt="Mr. Alok Khandelwal"
          sx={{ width: 85, height: 85, mr: 2 }}
        />
        
      
      <Box>
          <Typography variant="h6" fontWeight="bold">
            Mr. Alok Khandelwal
          </Typography>
          <Typography variant="body2" color="textSecondary">
            Advocate Indore
          </Typography>
          <Typography variant="body1" color="textSecondary" my={5}>
        Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, 
        sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. 
        Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, 
        adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et 
        dolore magnam aliquam quaerat voluptatem.
      </Typography>

      <Box display="flex" justifyContent="flex-start">
        {[...Array(5)].map((_, index) => (
          <StarIcon key={index} sx={{ color: "#FFC107", fontSize: 35 }} />
        ))}
      </Box>
        </Box>
        </Box>
      
    </Card>
  );
};

export default CustomerReviewCard;
