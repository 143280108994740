import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import CTOLargeImage from "../assets/largeLogo.png";
import Button from "@mui/material/Button";
export default function CTOLargeCard() {
    const theme = useTheme();

    return (
        <Box
            display="flex"
            flexDirection={{ xs: 'column', md: 'row' }}  // Stacks on smaller screens, side by side on larger
            alignItems="stretch"  // Makes both boxes take the same height
            sx={{
                width: '100%',
                height: {md:'50vh',xs:'10vh'},   // Adjust as needed
                gap: 10,
                py:4,
                px:{md:20}
            }}
        >
            {/* Left Box with Image */}
            <Box
                flex={1}
                sx={{
                    backgroundImage: `url(${CTOLargeImage})`, // Replace with your image URL
                    backgroundSize: 'contain',   // Ensures image fits within box without cropping
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    height: '100%',  // Ensures full height within the parent container
                }}
            />
            <Divider orientation="vertical" variant="small" flexItem 
                sx={{borderRightWidth: 2,height: '50vh'}}
            />
            {/* Right Box with Text and Button */}
            <Box
                flex={1}
                display="flex"
                flexDirection="column"
                justifyContent="center"
                padding={3}
                sx={{
                    height: '100%',  // Ensures it matches the height of the left box
                    gap:5,
                    alignItems: {sx:'center',md:'flex-start'}
                }}
            >
                <Typography variant="h4" sx={{fontWeight: 600}} gutterBottom>
                    About Property Mithra
                </Typography>
                <Typography variant="subtitle1" color='#4C5760' gutterBottom>
                    We also provide our services for Project marketing for all Residential, Commercial, Industrial and Agriculture Land. We offer our services in the field of constructions like Row House, Multistory Apartment & Commercial as per customer desired. As a prominent builder and developer, we are engaged in buying, selling, and renting/ leasing of the property Construction Services, Interior Designer and Civil Construction Services.
                </Typography>
                <Button variant="contained" sx={{py:1,px:2,fontSize:16,textTransform:'none',background:"#00B5EF"}}>
                    Read More
                </Button>
            </Box>
        </Box>
    );
}
