import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import CardActionArea from '@mui/material/CardActionArea';
import CardActions from '@mui/material/CardActions';

export default function LargeActionAreaCard({imageSrc,title}) {
  return (
    <Card sx={{ maxWidth: {md:650,xs:'100%'}, borderRadius:5, boxShadow: 3 }}>
      <CardActionArea>
        <CardMedia
          component="img"
          height="250"
          image={imageSrc}
        />
        <CardContent>
          <Typography gutterBottom component="div" sx={{fontSize: 35, fontWeight: 600}}>
            BRG Hills
          </Typography>
          <Typography sx={{ color: '#4C5760' }}>
          BRG Hills is a sought-after real estate destination known for its prime location and accessibility. Situated strategically, it provides easy access to essential amenities and major transportation routes, making it convenient for residents to reach key destinations. This vibrant neighborhood boasts a diverse range of properties, catering to various lifestyle preferences and offering a balanced blend of natural beauty and urban convenience.
          </Typography>
          <Button variant="contained" sx={{mt:5,py:1,px:2,fontSize:16,background:"#00B5EF"}}>
                    Get Callback
        </Button>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
