import React from 'react';
import { Box, Typography, Icon, Link,Button } from "@mui/material/";
import RectangularCardsData from "../constant/RectangularCardsData"
import CircularCard from "../components/CircularCard";
import RectangleCard from "../components/RectangleCard";
import CTOLargeCard from "../components/CTOLargeCard"
import cardImage1 from "../assets/image1.png";
import cardImage2 from "../assets/image2.png";
import cardImage3 from "../assets/image3.png";
import largeCardeImage1 from "../assets/largeCardRaw1.png";
import largeCardeImage2 from "../assets/largeCardRaw2.png";
import largeCardeImage3 from "../assets/largeCardRaw3.png";
import LargeCardRaw from "../components/LargeCardRaw";
import LargeActionAreaCard from '../components/LargeActionAreaCard';
import EmailIcon from '@mui/icons-material/Email';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PhoneIcon from '@mui/icons-material/Phone';
import Logo from "../assets/logo.svg";
import HorizontalBackground from "../assets/largehorizontalbackground.png";
import { ReactComponent as LogoBlue } from "../assets/logoblue.svg";
import CustomerReviewCard from '../components/CustomerReviewCard';
import Customer1 from "../assets/customer1.png";
import Customer2 from "../assets/customer2.png";

const titleLargeCard1 = "Rivera Hills";
const titleLargeCard2 = "Ralamandal Hills";
function Home() {
    return (
        <div>
            {/* Circular Cards Section */}
            <Box sx={{
                background: { xs: '#415A6A', md: 'inherit' },
                display: 'flex',
                flexDirection: { md: 'row', xs: 'column' }, // Align cards horizontally // Space between the cards
                p: 4,
                gap: { md: 15 },
                justifyContent: 'center', // Center cards horizontally
                alignItems: 'center',
                position: { sm: 'absolute', xs: 'relative' }, // Position it above the next section
                top: { md: '100%' }, // Position the cards 50% above the next section
                left: { md: '50%' }, // Align it in the center of the screen
                transform: { md: 'translate(-50%, -140%)' }, // Center horizontally and vertically
                zIndex: 1, // Ensure this content appears above other content
            }} >
                <Box sx={{ display: "flex", flexDirection: { xs: 'row' }, gap: { md: 15, xs: 2 } }}>
                    <CircularCard
                        imageSrc={cardImage1}
                        text="Residental"
                    />
                    <CircularCard
                        imageSrc={cardImage2}
                        text="Commercial"
                    />
                </Box>
                <Box sx={{ display: 'flex', flexDirection: { xs: 'row' } }}>
                    <CircularCard
                        imageSrc={cardImage3}
                        text="For Hot Sale"
                    />
                </Box>
            </Box>

            {/* Typography */}
            <Box sx={{
                display: 'flex',
                flexDirection: "column",
                alignItems: 'center',
                gap: 2,
                p: 4,
                mt: { xs: 5, md: 30 }
            }}>
                <Typography sx={{ fontWeight: 800, fontSize: 35 }}>
                    Explore Top Property and Buildings
                </Typography>
                <Typography variant="subtitle1" sx={{ color: '#4C5760', fontSize: 16 }}>
                    Discover the Magic of Property with Shree Shivay Infrastructure Property Your Gateway to Exceptional
                    Real Estate Opportunities
                </Typography>
                <Typography variant="subtitle1" sx={{ color: '#4C5760', fontSize: 16 }}>
                    "Transforming properties into dreams Shree Shivay Infrastructure Property, where your real estate
                    aspirations find their enchanting reality."
                </Typography>
            </Box>

            {/* Rectangular Card */}
            <Box sx={{
                display: 'flex',
                flexDirection: 'row',
                overflowX: 'auto', // Enables horizontal scrolling
                gap: 2, // Space between cards
                p: 2, // Padding around the container
                justifyContent: 'center',
                "&::-webkit-scrollbar": {
                    height: 8, // Scrollbar height
                },
                "&::-webkit-scrollbar-thumb": {
                    backgroundColor: "white",
                },
                "&::-webkit-scrollbar-thumb:hover": {
                    backgroundColor: "#555",
                },
            }}>
                {RectangularCardsData.map((item) => (
                    <RectangleCard
                        imageSrc={item.image}
                        title={item.title}
                        tag={item.tag}
                    />
                ))}
            </Box>

            {/* CTO */}
            <Box sx={{ px: 20, my: 10 ,mt:{xs:40},display:{xs:'none',md:'flex'} }}>
                <CTOLargeCard />
            </Box>

            {/* Typography */}
            <Box sx={{ display: 'flex', flexDirection: "column", alignItems: 'center', gap: 2, p: 4 }}>
                <Typography sx={{ fontWeight: 800, fontSize: 35 }}>
                    Our Luxury Project
                </Typography>
                <Typography variant="subtitle1" sx={{ color: '#4C5760', fontSize: 16 }}>
                    Residential Developments, Mixed-Use Developments, Industrial Facilities, Infrastructure Development,
                    Real Estate </Typography>
                <Typography variant="subtitle1" sx={{ color: '#4C5760', fontSize: 16 }}>Investment and Management, Green Building Initiatives, Urban Renewal and Redevelopment.
                </Typography>
            </Box>

            {/* Large card CTO */}
            <Box sx={{ display: 'flex', flexDirection: {md:'row',xs:'column'}, gap: 3, p: 3 }}>
                <LargeCardRaw imageSrc={largeCardeImage1} title={titleLargeCard1} />
                <LargeActionAreaCard imageSrc={largeCardeImage3} />
                <LargeCardRaw imageSrc={largeCardeImage2} title={titleLargeCard2} />
            </Box>

            {/* Client Cards */}
            <Box sx={{ display: 'flex', flexDirection: "column", alignItems: 'center', gap: 4, p: 10 }}>
                <Typography sx={{ fontWeight: 800, fontSize: 35 }}>
                    Our Happy Clients Say About Us
                </Typography>
                <Box sx={{display: 'flex', flexDirection:{md:'row',xs:'column'}, gap:5}}>
                <CustomerReviewCard avatar={Customer1} />
                <CustomerReviewCard avatar={Customer2} />
                </Box>
            </Box>

            {/* Large Horizontal Design */}
            <Box
                sx={{
                    position: 'relative', // Ensures overlay positioning works
                    backgroundImage: `url(${HorizontalBackground})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    height: {md:'60vh',xs:'40vh'},
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <Box sx={{
                    display:{xs:"none"},
                    height: '20%',
                    display: 'flex',
                    py: 10,
                    justifyContent: 'center',
                    gap: 4,
                    px:{xs:5}
                }}>
                    <Typography sx={{ color: 'white', fontWeight: 500, fontSize: {md:55}, py: 8 }}>
                        यहाँ मिलेगी अपने
                    </Typography>
                    <LogoBlue fontWeight={800} fontSize={55} sx={{ fontWeight: 500, fontSize: {md:35} }} />
                    <Typography sx={{ color: 'white', fontWeight: 500, fontSize: {md:55}, py: 8 }}>
                        की बेस्ट डील
                    </Typography>
                </Box>
                <Box sx={{
                    display:{xs:"none",md:"flex"},
                    pt: 10,
                    pb: 5,
                    justifyContent: 'center',
                    gap: 4
                }}>
                    <Typography sx={{ color: 'white', fontWeight: 100, fontSize: 25 }}>
                        Leave us a query and our representative will get back to you.
                    </Typography>
                    
                </Box>
                <Box sx={{
                    textTransform:'none',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'flex-start',
                    py:{xs:10}
                }}>
                <Button variant="contained" sx={{ py: 1, px: 2, fontSize: 16,fontWeight:800, background: "#00B5EF" }}>
                        Get Callback
                    </Button>
                </Box>
            </Box>

            {/* About Us */}
            <Box
                component="footer"
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    py: 10,
                    px: {md:30},
                    backgroundColor: '#ffffff',
                    flexDirection:{md:"row",xs:"column"}
                }}>
                {/* Logo Section */}
                <Box sx={{ textAlign: 'center' }}>
                    <img src={Logo} alt="Shree Shivay Infrastructure Logo"
                        style={{
                            width: '150px', // Set desired width
                            height: 'auto', // Maintains aspect ratio
                        }} />
                    <Typography
                        sx={{
                            fontSize: 30,
                            fontWeight: 'bold',
                            color: '#00aaff',
                        }}>
                        Shree Shivay Infrastructure
                    </Typography>
                    <Typography sx={{ fontSize: 26, fontWeight: 100, color: 'black' }}>
                        Real Estate & Construction
                    </Typography>
                </Box>

                {/* Quick Links Section */}
                <Box sx={{ display: 'flex',alignItems: 'flex-start' ,flexDirection: 'column', gap: 2,my:{xs:6},mr:{xs:10} }}>
                    <Typography variant="h6"
                        sx={{ fontWeight: 'bold', mb: 4 }}>
                        Quick Links
                    </Typography>{['Individual Plots', 'RERA Approved Plots', 'Agri Lands and Farm House', 'Independent House', 'Apartments'].map((link, index) => (
                        <Link
                            href="#"
                            sx={{
                                textDecoration: 'none',
                                color: '#000',
                                '&:hover': { color: '#00aaff' }
                            }}>
                            {link}
                        </Link>
                    ))}
                </Box>

                {/* Contact Us Section */}
                <Box sx={{ display: 'flex', alignItems: 'flex-start', flexDirection: 'column', gap: 2, mb: 6 }}>
                    <Typography
                        variant="h6"
                        sx={{ fontWeight: 'bold', mb: 4 }}>
                        Contact Us
                    </Typography>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                        <EmailIcon sx={{ color: '#00aaff' }} />
                        <Typography>
                            shreeshivayinfrastructure@gmail.com
                        </Typography>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                        <LocationOnIcon sx={{ color: '#00aaff' }} />
                        <Typography>
                            B-19, BRG Hill View Indore - 452001
                        </Typography>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                        <PhoneIcon sx={{ color: '#00aaff' }} />
                        <Typography>
                            Bharat Patel: +91 78980-08922
                        </Typography>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                        <PhoneIcon sx={{ color: '#00aaff' }} />
                        <Typography>
                            Chetan Kushwaha: +91 88788-56143
                        </Typography>
                    </Box>
                </Box>
            </Box>
        </div>
    );
}

export default Home;
