import rectangleImage1 from "../assets/rectangle1.png";
import rectangleImage2 from "../assets/rectangle2.png";
import rectangleImage3 from "../assets/rectangle3.png";
import rectangleImage4 from "../assets/rectangle4.png";
import rectangleImage5 from "../assets/rectangle5.png";

const RectangularCardsData = [
    {'title':'Individual Plots','tag':'13 property','image':rectangleImage1},
    {'title':'RERA approved Plots','tag':'10 property','image':rectangleImage2},
    {'title':'Agri Lands and Farm House','tag':'16 property','image':rectangleImage3},
    {'title':'Independent House','tag':'13 property','image':rectangleImage4},
    {'title':'Apartments','tag':'10 property','image':rectangleImage5}
]
export default RectangularCardsData;